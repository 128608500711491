<template>
  <div class="WebCast">
    <WebCastSkeleton v-if="!isReady" />
    <div :class="{ 'hide-element': !isReady }" :key="elmkey">
      <template v-for="(pageSection, idx) in webcastMultimedia">
        <component :is="pageSection.typeSection" :key="idx" :data="pageSection"></component>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from "vuex";
  export default {
    components: {
      WebCastHeaderSlide: () => import("./WebCastHeaderSlide.vue"),
      WebCastRecentSlider: () => import("./WebCastRecentSlider.vue"),
      ModelsTestimonials: () => import("./WebCastKnowMore.vue"),
      WebCastInsideModels: () => import("./WebCastInsideModels.vue"),
      WebCastOthers: () => import("./WebCastOthers.vue"),
      Travels: () => import("./WebCastKnowMore.vue"),
      WebCastSkeleton: () => import("./WebCastSkeleton.vue"),
    },
    data: function () {
      return {
        isReady: false,
        elmkey: 0,
        pageContent: [],
      };
    },
    metaInfo() {
      const { webcast = {} } = this.locale;
      const { title = "", description = "", keywords = "" } = webcast;

      return {
        title,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description,
          },
          {
            vmid: "robots",
            name: "robots",
            content: "index, follow",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: keywords,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
        ],
      };
    },
    computed: {
      ...mapState(["webcastMultimedia"]),
      ...mapGetters(["newLocale"]),
      locale() {
        return this.newLocale;
      },
    },
    methods: {
      ...mapActions(["getWebcastMultimedia"]),
    },
    beforeMount() {
      this.getWebcastMultimedia();
    },
    mounted() {
      this.isReady = true;
      this.elmkey++;
    },
  };
</script>

<style lang="scss">
  .WebCast {
    h2,
    h3,
    h4 {
      font-family: "BebasKai", sans-serif;
      font-weight: 300;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 25px;
      font-weight: 200;
    }

    p,
    span {
      font-size: 15px;
      font-family: Montserrat;
    }
    button {
      background: transparent;
    }
    .content-margin {
      max-width: 1280px;
      margin: 20px auto;
    }

    .hide-element {
      visibility: hidden;
      overflow: hidden;
      height: 0;
    }
    .poligon-title {
      display: inline-block;
      clip-path: polygon(0 0, 95% 0, 87% 100%, 0 100%);
      margin-bottom: 33px;
      padding: 10px 10% 10px 28px;
      background-color: $primary_color;
      color: #fff;
      margin-left: -20px;
      h3 {
        margin-left: 40px;
        padding-right: 20px;
        font-weight: 500;
      }
    }

    button {
      border: none;
      cursor: pointer;
    }
    h2 {
      font-size: 40px;
      font-weight: 400;
    }
    &__knowMore {
      background: #f6f6f6;
      padding: 25px 0;
      .knowMoreTitle {
        color: $primary_color;
        text-align: center;
      }
    }

    @include MobileSmall {
      .content-margin {
        margin-bottom: 20px;
      }
    }
    @include Desktop {
      h2 {
        font-size: 42px;
      }
      h3,
      h4 {
        font-size: 23px;
      }
      .content-margin {
        width: 90%;
      }
      .poligon-title {
        h3 {
          font-size: 30px;
        }
      }

      &__knowMore {
        .knowMoreTitle {
          color: $primary_color;
          text-align: start;
        }
      }
      .poligon-title {
        margin-left: 0;
      }
    }
  }
</style>
